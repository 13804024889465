export default {
  /**
   * 根据key value 获得数组中第一个匹配对象
   * @param array
   * @param key
   * @param value
   * @returns {null|*}
   */
  getArrayObject(array, key, value) {
    key = key.split(':')
    for (const item in array) {
      let temp = array[item]
      for (const k in key) {
        // eslint-disable-next-line eqeqeq
        if (temp[key[k]] == value) {
          array[item].$index = item
          return array[item]
        }
        temp = temp[key[k]]
      }
    }
    return null
  },
  getArrayByKeyToObject(array, key, value) {
    key = key.split(':')
    const f = []
    for (const item in array) {
      let temp = array[item]
      for (const k in key) {
        // eslint-disable-next-line eqeqeq
        if (temp[key[k]] == value) {
          array[item].$index = item
          f.push(temp)
        }
        temp = temp[key[k]]
      }
    }
    return f
  },
  /**
   * 获取数组元素对象的key属性
   * @param array
   * @param key
   * @returns {*[]}
   */
  getArrayObjectField(array, key) {
    key = key.split(':')
    const list = []
    for (const item in array) {
      let temp = array[item]
      for (const k in key) {
        temp = temp[key[k]]
      }
      list.push(temp)
    }
    return list
  },
  getArrayObjectFieldByOtherField(array, key, findKey, findValue) {
    key = key.split(':')
    findKey = findKey.split(':')
    const list = []
    for (const item in array) {
      let temp = array[item]
      for (const f in findKey) {
        // eslint-disable-next-line eqeqeq
        if (temp[findKey[f]] == findValue) {
          temp = array[item]
          for (const k in key) {
            temp = temp[key[k]]
          }
          list.push(temp)
        }
        temp = temp[findKey[f]]
      }
    }
    return list
  },
  getArrayObjectFieldByOtherFieldArray(array, key, findKey, findValue) {
    key = key.split(':')
    findKey = findKey.split(':')
    const list = []
    for (const item in array) {
      let temp = array[item]
      for (const f in findKey) {
        // eslint-disable-next-line eqeqeq
        if (findValue.includes(temp[findKey[f]])) {
          temp = array[item]
          for (const k in key) {
            temp = temp[key[k]]
          }
          list.push(temp)
        }
        temp = temp[findKey[f]]
      }
    }
    return list
  },
}
